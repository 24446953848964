.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.date-wrapper {
  display: flex;
  font-size: 24px;
}

.shuffle-container {
  margin-top: 16px;
  min-width: 700px;
}

.pairs-wrapper {
  display: flex;
  flex-direction: row;
  flex: 8;
}

.pair-cell {
  display: flex;
  flex: 4;
  justify-content: center;
  padding: 16px;
  outline: 1px solid #fff; /* use instead of border */
  margin-top: 1px;
  margin-left: 1px;
}

.names-wrapper {
  display: flex;
  flex-direction: row;
  flex: 8;
}

.name-cell {
  display: flex;
  flex: 2;
  justify-content: center;
  padding: 8px;
  outline: 1px solid #fff; /* use instead of border */
  margin-top: 1px;
  margin-left: 1px;
}

.shuffle-button-wrapper {
  margin-top: 16px;
}

.shuffle-button {
  font-size: 24px;
  padding: 16px;
  border-radius: 5;
}
